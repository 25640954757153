.buttons-to-pages-dark {
    overflow-x: hidden;
    margin-top: 0em;
    background-color: var(--second-book);
    a {
        color: var(--primary-color);
        text-decoration: none;
    }
    
    .grid-container {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-evenly;
        align-items: center;
        justify-items: center;
        font-family: "Ruda";
    }
    
    section {
        padding: 0em;
    }

    .grid-item {
        background-color: var(--third-color);
        text-decoration: none;
        padding: .6em 1.3em;
        font-size: 1.4em;
        border-radius: 5em;
        display: inline-block;
        text-align: center;
    }

    h1 {
        margin-top: 0em;
        margin-bottom: .5em;
        display: flex;
        font-size: 60px;
        color: var(--secondary-color);
        align-items: center;
        justify-content: center;
    }
}

hr {
    border: 10px inset;
    color: rgb(0, 0, 0);
    display: block;
    height: 2px;
    margin-top: 0em;
    margin-bottom: 0em;
}