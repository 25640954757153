@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&family=Ruda&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;600;700&family=Ruda&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital@1&display=swap" rel="stylesheet');

:root {
    --primary-color: rgb(255, 255, 255);
    --secondary-color: rgb(255, 166, 0);
    --third-color: rgb(0, 0, 0);
    --first-book: #414141;
    --second-book: #313131;
}

*{
    margin:0px;
    padding:0px;
    //overflow-x: auto;
}

//chrome and other browsers scrollbar
html {
    scroll-behavior: smooth;
    --scrollbarBG: var(--third-color);
    --thumbBG: var(--secondary-color);
}

body::-webkit-scrollbar {
    width: 11px;
    height: 11px;
}

body {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

body::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);

    background-color: var(--secondary-color);	
	background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%,  rgba(255, 255, 255, .2) 75%, transparent 75%, transparent);
}

//Firefox scrollbar   
html{
    scrollbar-color: var(--secondary-color) var(--third-color);
}

body {
    background: var(--primary-color);
    margin: 0;
    font-family: 'Dancing Script', cursive;
}

.active{
    font-weight: bold;
    color: var(--secondary-color) ;
}
.navbar {
    background: var(--second-book);
    padding: 1em;
    display:flex;
    justify-content: space-between;
    .name {
      text-decoration: none;
      font-weight: bold;
      color: var(--primary-color);
      font-size: 1.2em;
    }

    span {
        color: var(--secondary-color);
    }

    nav {
        display: none;
    }
    
    .container {
        display: flex;
        place-content: space-between;
    }

    .mobile-menu {
        cursor: pointer;
    }
    .menu-btn{
        display: block;
    }
}

ul ul {
    display: none;
    list-style: none;
}

nav {
    position: fixed;
    // z-index: 999;
    width: 30vw;
    right: 0;
    top: 0;
    background: #474747;
    height: 100vh;
    padding: 1em;

    ul.primary-nav {
        margin-top: 5em;
    }
    
    .inside-list {
        position:absolute !important;
        // left:50px;
        width: 25vw;
        background: rgb(38, 37, 37);
        padding: 0em;
        // text-align: left;
        li:hover{
            background: rgba(0,0,0,0.4);
        }
    }

    .inside-list a {
        text-align: center;
    }
    
    li {
        list-style: none;
        a {
            font-family: "Ruda";
            color: var(--primary-color);
            text-decoration: none;
            display: block;
            padding: .5em;
            font-size: 1.3em;
            text-align: right;

            &:hover {
                font-weight: bold;
            }
        }
    }
}

.mobile-menu-exit {
    float: right;
    margin: .5em;
    cursor: pointer;
}

@media only screen and (min-width: 768px) {
    .mobile-menu, .mobile-menu-exit {
        display: none;
    }    
    
    .navbar{
        display: block;
    }
    .navbar .container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // grid-template-columns: 180px auto;
    }
    
    .navbar nav {
        display: flex;
        width:fit-content;
        // justify-content: space-between;
        background: none;
        position: unset;
        height: auto;
        // width: 100%;
        padding: 0;
        
        .inside-list{
            display: none;
            position:absolute;
            width:250px;
        }
        ul {
            display: flex;
        }

        a {
            font-size: 1em;
            padding: .1em 1em;
        }

        ul.primary-nav {
            margin: 0;
        }
        
        li.current a {
            font-weight: bold !important;
            color: var(--secondary-color) !important;
        }

        li.current-contact a {
            font-weight: bold;
            color: var(--secondary-color);
            border: 3px solid var(--secondary-color) !important;
            font-weight: bold;
            border-radius: 5em;
            margin-left:30px;
            margin-top:-.2em;

            &:hover {
                background-color: var(--third-color);
                font-weight: bold;
            }
        }

        li.contact a {
            color: var(--primary-color);
            border: 3px solid var(--primary-color) !important;
            font-weight: bold;
            border-radius: 5em;
            margin-left:30px;
            margin-top:-.2em;
            // margin-top: -1.3em;

            &:hover {
                background-color: var(--primary-color);
                color: var(--third-color);
                font-weight: bold;
            }
        }

        .drop-grid a{
            float: none;
            text-align: left;
            display: block;
        }
    }
}