.list-of-books {
    .book {
        .grid-container-two {
            img {
                padding-bottom: 2em;
            }
            .mentioned-books {
                padding: .3em;

                h4 {
                    color: rgb(134, 134, 134);
                }

                h5 {
                    color: rgb(134, 134, 134);
                }

                h6 {
                    color: rgb(134, 134, 134);
                }
            }
        }
    }
}

.list-of-books {
    .book {
        .grid-container {
            img {
                padding-bottom: 2em;
            }
            
            .mentioned-books {
                padding: .3em;

                h4 {
                    color: rgb(134, 134, 134);
                }

                h5 {
                    color: rgb(134, 134, 134);
                }

                h6 {
                    color: rgb(134, 134, 134);
                }
            }
        }
    }
}