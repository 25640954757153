.list-of-books {

    .book {
        display: flex;
        flex-flow: wrap row;
        align-items: center;
        // justify-content: center;
        justify-content: space-evenly;
    }

    object {
        flex:1.5;
        margin: 2em;
    }

    .grid-container{
        padding-top: 2em;
        flex:1;
        display: flex;
        flex-wrap: wrap !important;
        justify-content: space-evenly;
    }

    a {
        color: var(--primary-color);
        padding-bottom: .3em;
        text-decoration: none;
        font-family: "Ruda";
        font-size: 2vw;

        // .mentioned-books {
        //     //color:#414141;
        //     //justify-content: space-between;
        // }

        // .grid-container-two {
        //     display: grid;
        //     grid-template-rows: auto;
        //     grid-template-columns: auto auto;
        //     //justify-content: space-between;
        //     //align-items: center;
        //     //justify-items: center;
        //     font-family: "Ruda";
        // }
    }

    img {
        height: 200px;
        width: 120px;
    }

    object {
        width: 80vw;
        height: 80vh;
    }
}

#first-book {
    background-color: var(--second-book);
    margin-top: 0em;
    margin-bottom: 0em;
}

#second-book {
    background-color: var(--first-book);
    margin-top: 0em;
    margin-bottom: 0em;
}

#third-book {
    background-color: var(--second-book);
    margin-top: 0em;
    margin-bottom: 0em;
}

#fourth-book {
    background-color: var(--first-book);
    margin-top: 0em;
    margin-bottom: 0em;
}

#fifth-book {
    background-color: var(--second-book);
    margin-top: 0em;
    margin-bottom: 0em;
}

#sixth-book {
    background-color: var(--first-book);
    margin-top: 0em;
    margin-bottom: 0em;
}

.table-of-contents {
    background-color: var(--first-book);

    padding: 20px 0;

    h1 {
        margin-top: 0em;
        margin-bottom: .5em;
        display: flex;
        font-size: 60px;
        color: var(--secondary-color);
        align-items: center;
        justify-content: center;
    }

    h2 {
        margin-top: 0em;
        margin-bottom: .5em;
        display: flex;
        font-size: 30px;
        color: var(--secondary-color);
        align-items: center;
        justify-content: center;
    }

    .table-element {
        font-family: "Ruda";
        color: var(--primary-color);
        display: block;
        padding: .5em;
        font-size: 1.3em;
        text-align: center;
    }

    .table-title {
        h1 {
            color: var(--primary-color);
            margin-bottom: .3px !important;
        }

        h2 {
            color: var(--primary-color);
            font-size: 40px;
            margin-bottom: .3px !important;
            margin-top: 20px !important;
        }

        h3 {
            color: rgb(202, 144, 90);
            font-size: 15px;
            margin-bottom: .3px !important;
        }
    }

    .table-content {

        .table-element {
            font-family: 'Merriweathe';
            color: var(--primary-color);
            display: block;
            padding: .5em;
            font-size: 1.3em;
            text-align: center;

            a {
                text-decoration: none;
    
                &:hover {
                    font-weight: bold;
                    color: var(--secondary-color);
                }
                
                &:visited {
                    color: var(--primary-color);
                }
            }
    
            a:visited {
                color: var(--primary-color);
            }
    
            a:link {
                color: var(--primary-color);
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    object {
        width: 60vw;
        height: 80vh;
    }

    img {
        width: 20vw !important;
        height: 50vh !important;
    }
}