.second-hero {
    h1 {
        margin-top: 0em;
        display: flex;
        background-image: linear-gradient(180deg, rgba(145, 146, 122, 0.179), rgba(9, 9, 9, 0.58)), url('/assets/picture-with-books.jpg');
        background-size: contain;
        background-repeat: repeat-x;
        background-color: var(--first-book);
        height: 100vh;
        background-position: center;
        font-size: 10vw;
        color: var(--primary-color);
        align-items: center;
        justify-content: center;
        // padding: 1em;
        margin-bottom: 0em;
        vertical-align: middle; 
        text-align: center; 
    }
}

.inner-feelings {
    background-color: var(--second-book);

    h1 {
        margin-top: 0em;
        margin-bottom: .5em;
        display: flex;
        font-size: 60px;
        color: var(--secondary-color);
        align-items: center;
        justify-content: center;
    }

    a:link {
        text-decoration: none;
    }


    a:visited {
        text-decoration: none;
    }


    a:hover {
        text-decoration: none;
    }


    a:active {
        text-decoration: none;
    }
    
    h2 {
        margin-top: 0em;
        margin-bottom: .5em;
        display: flex;
        padding: .5em;
        color: rgb(202, 144, 90);
        font-size: 15px;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        font-family: 'Merriweather';
    }

    object {
        width: 40vw;
        height: 60vh;
        padding-bottom: .5em;
    }
}

.list-of-books {
    .book {
        .grid-container-two {
            .awards {
                    padding: .3em;
                h2 {
                    margin-top: 0em;
                    margin-bottom: .5em;
                    display: flex;
                    font-size: 30px;
                    color: var(--primary-color);
                    align-items: center;
                    justify-content: center;
                    font-family: 'Merriweather';
                }

                h3 {
                    color: rgb(134, 134, 134);
                    font-family: 'Merriweather'
                }

                h4 {
                    color: rgb(134, 134, 134);
                    font-family: 'Merriweather'
                }
            }

            .grid-double-image {
                display: flex;
                flex-direction: row;
                
                .book-cover {
                    width: 50%;
                    justify-content: space-between;
                }
            }

            .award {
                img {
                    height: 216px;
                    width: 370px;
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    object {
        width: 60vw;
        height: 80vh;
        padding-bottom: .5em;
    }
    
    .list-of-books {
        .book {
            .grid-container-two {
                .award {
                    img {
                        height: 216px !important;
                        width: 400px !important;
                    }
                }
            }
        }
    }
}