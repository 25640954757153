.hero {
    h1 {
        margin-top: 0em;
        display: flex;
        background-image: linear-gradient(180deg, rgba(145, 146, 122, 0.179), rgba(9, 9, 9, 0.58)), url('/assets/book-pictures-low-brightness.jpg') ;
        height: 100vh;
        background-position: center;
        font-size: 10vw;
        color: var(--primary-color);
        align-items: center;
        justify-content: center;
        // padding: 1em;
        margin-bottom: 0em;
        vertical-align: middle;
        text-align: center;
    }
}